<mat-spinner class="loading-main" *ngIf="spinner"></mat-spinner>
<div *ngIf="!spinner">
  <div class="buttons-row-container" id="buttons-row">
    <div class="filters">
      <button mat-raised-button class="filter-btn-hide-show short-button"
        [style.background-color]="theme.getColor('Main Light')" (click)="toggleAppNamesNav()">{{ buttonTextFilter
        }}
        les filtres
      </button>
      <button class="short-button filter-btn" mat-raised-button *ngIf="isAppNamesNavVisible"
        (click)="resetFilters()">Vider les filtres</button>

    </div>
    <div class="go_real">
      <button mat-raised-button class="indigo filter-btn short-button"
        [style.background-color]="theme.getColor('Main Dark')" [ngClass]="{'current' : searchIndex === 'go' }"
        (click)="updateApplicationFilter('go')">GO Réal
      </button>
    </div>
    <div class="jalons">
      <button mat-raised-button class="indigo filter-btn short-button"
        [style.background-color]="theme.getColor('Main Dark')" [ngClass]="{'current' : searchIndex === 'due' }"
        (click)="updateApplicationFilter('due')">Jalons
      </button>
    </div>
    <div class="a_traiter">
      <button mat-raised-button class="indigo filter-btn short-button"
        [style.background-color]="theme.getColor('Main Dark')" [ngClass]="{'current' : searchIndex === 'treat' }"
        (click)="updateApplicationFilter('treat')">A traiter
      </button>
    </div>
    <div class="retard">
      <button mat-raised-button class="indigo filter-btn short-button"
        [style.background-color]="theme.getColor('Main Dark')" [ngClass]="{'current' : searchIndex === 'late' }"
        (click)="updateApplicationFilter('late')">Retard
      </button>
    </div>
    <div class="perimetre_appli">
      <mat-form-field class="filter-btn long-button" appearance="outline">
        <mat-label>Périmètre Applicatif</mat-label>
        <mat-select panelClass="panel" [formControl]="perimeterControl" (ngModelChange)="onPerimeterChange()">

          <mat-option [value]="''">Périmètre par défaut</mat-option>

          <mat-optgroup label="Domaine" *ngIf="dsis[0]">
            <mat-option *ngFor="let dsi of dsis" [value]="dsi">
              {{dsi.name}}
            </mat-option>
          </mat-optgroup>

          <mat-optgroup label="Acteur" *ngIf="users[0]">
            <mat-option *ngFor="let user of users" [value]="user">
              {{user.firstName! + " " + user.lastName!.toUpperCase()}}
            </mat-option>
          </mat-optgroup>

        </mat-select>
      </mat-form-field>
    </div>

    <div class="jira">
      <div><span class="date-label" *ngIf="applicationSettings.JIRA_Extract_Date.charAt(0) == '2'"> Synchro JIRA du
          {{applicationSettings.JIRA_Extract_Date | date:'dd/MM/YYYY'}}
          à {{applicationSettings.JIRA_Extract_Date | date:'HH:mm'}} </span>
        <span class="date-label" *ngIf="applicationSettings.JIRA_Extract_Date.charAt(0) != '2'"> Synchro JIRA (date
          invalide) </span>
      </div>
      <div class="demands-action">
        <button [style.background-color]="theme.getColor('Main Light')" title="Valider" id="validate-demand"
          mat-mini-fab color="primary" (click)="validateDemands()"
          [disabled]="!(enableValidation) || lockValidation || isAlreadyValidating">
          <mat-icon>check</mat-icon>
        </button>
        <button style="margin-left: 10px;" [style.background-color]="theme.getColor('Main Dark')" title="Rejeter"
          id="reject-demand" mat-mini-fab color="primary" (click)="rejectDemands()"
          [disabled]="!(enableRejection) || lockValidation || isAlreadyValidating">
          <mat-icon>close</mat-icon>
        </button>
      </div>

    </div>
  </div>

  <div id="demands-container">

    <div id="app-names-nav" *ngIf="isAppNamesNavVisible">

      <div id="app-names-table">
        <table>
          <caption class="displayNone">Liste des applications</caption>
          <th class="displayNone">Applications</th>
          <tr *ngFor="let application of applications; let i = index;">
            <td matRipple [class]="(application.selected ? '' : 'un') + 'selected-card'"
              [style.color]="theme.getColor('List Dark')" [style.background-color]="theme.getColor('List Light')"
              (click)="application.selected = !application.selected" class="app-name-item">
              {{ application.name }}
            </td>
          </tr>
        </table>

      </div>

      <div id="pres-names-table">
        <table>
          <caption class="displayNone">Liste des prestations</caption>
          <th class="displayNone">Prestations</th>
          <tr *ngFor="let prestation of prestations; let i = index;">
            <td matRipple [class]="(prestation.selected ? '' : 'un') + 'selected-card'"
              [style.color]="theme.getColor('List Alt Dark')"
              [style.background-color]="theme.getColor('List Alt Light')"
              (click)="prestation.selected = !prestation.selected" class="pres-name-item">
              {{ prestation.name }}
            </td>
          </tr>
        </table>

      </div>

      <div class="button2lines">
        <button mat-raised-button class="filter-btn" (click)="disableApplicationFilter()">Vider la selection</button>
        <div>
          <button mat-raised-button class="indigo half-filter-btn"
            [style.background-color]="theme.getColor('Main Dark')" [ngClass]="{'current' : searchIndex === 'search' }"
            (click)="updateApplicationFilter('search')">
            Rechercher
          </button>
          <button mat-raised-button class="blue half-filter-btn" [style.background-color]="theme.getColor('Main Light')"
            [ngClass]="{'current-alt' : searchIndex === 'facture' }" (click)="updateApplicationFilter('facture')">
            Facturable
          </button>
        </div>
      </div>
    </div>

    <mat-spinner class="loading" *ngIf="isTableLoading"></mat-spinner>
    <div id="rightContainer">

      <div class="allDemandsFilterContainer" *ngIf="isAppNamesNavVisible">
        <div class="numberCommandFilter">
          <mat-form-field appearance="outline">
            <mat-label>Filtre Commande</mat-label>
            <input id="filterReference" matInput [(ngModel)]="reffilter" (keyup)="reffilterList($event)">
          </mat-form-field>
        </div>
       
        <div class="numberDemandFilter">
          <mat-form-field appearance="outline">
            <mat-label>Filtre Demande</mat-label>
            <input matInput [(ngModel)]="filter" (keyup)="filterList($event)">
          </mat-form-field>
        </div>
        <div class="DescriptionFilter">
          <mat-form-field appearance="outline">
            <mat-label>Filtrer Description</mat-label>
            <input matInput [(ngModel)]="desfilterValue" (keyup)="desfilterList($event)">
          </mat-form-field>
        </div>
        <div class="GalileiFilter">
          <mat-form-field appearance="outline">
            <mat-label>Filtre Galiléi</mat-label>
            <input matInput [(ngModel)]="galfilterValue" (keyup)="galfilterList($event)">
          </mat-form-field>
        </div>
        <div class="packYearFilter">
          <mat-form-field appearance="outline">
            <mat-label>Année Pack</mat-label>
            <mat-select multiple [(ngModel)]="displayYears" (closed)="getFilteredAndPagedDemandsAndTotals()">
              <mat-option *ngIf="settingYear" [value]="(year - 1)">{{year - 1}}</mat-option>
              <mat-option [value]="(year)">{{year}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="StateFilter">
          <mat-form-field appearance="outline">
            <mat-label>Etat demandes</mat-label>
            <mat-select [formControl]="displayDemandsStatus" multiple (closed)="getFilteredAndPagedDemandsAndTotals()">
              <mat-option *ngFor="let status of demandStatus" [value]="status" [ngClass]="statusColor(status)">
                {{ status }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="ServiceFilter">
          <mat-form-field appearance="outline">
            <mat-label>Filtre service</mat-label>
            <mat-select [formControl]="displayServiceFilter" multiple (closed)="getFilteredAndPagedDemandsAndTotals()">
              <mat-option *ngFor="let service of serviceFilter" [value]="service">
                {{ service }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
      </div>

      <div id="demandsTableContainer">
        <mat-table [dataSource]="Array.from(demands.values())" matSort matSortActive="reference"
          (matSortChange)="paginator.pageIndex = 0" class="light mat-elevation-z4 table" id="demands-table"
          [ngClass]="{'full-width': !isAppNamesNavVisible}">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="checkboxColumn" sticky>
            <mat-header-cell class="checkbox-cell first-checkcell" mat-header *matHeaderCellDef>
              <mat-checkbox [(ngModel)]="selectAll" (ngModelChange)="selectAllDemands()" [disabled]="lockValidation">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell class="checkbox-cell" *matCellDef="let element">
              <mat-checkbox [(ngModel)]="element.selected" (change)="updateDemandsValidationAndRejectionCapabilities()"
                [disabled]="(!canBeValidated(element) && !element.rejectable) || lockValidation || currentDue(element).cost == 0">
              </mat-checkbox>
            </mat-cell>
            <mat-footer-cell class="footer-cell" *matFooterCellDef>
            </mat-footer-cell>
          </ng-container>

          <!-- ManualData Column -->
          <ng-container matColumnDef="manualData" sticky>
            <mat-header-cell class="md-cell manual" *matHeaderCellDef mat-header></mat-header-cell>
            <mat-cell class="md-cell manual" *matCellDef="let element" (click)="openDemandDetails(element)"><b
                *ngIf="hasManualData(element)">+</b></mat-cell>
            <mat-footer-cell class="md-cell manual" *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- OrderNumber Column -->
          <ng-container matColumnDef="orderNumber" sticky>
            <mat-header-cell class="order-cell" *matHeaderCellDef mat-header> N° Commande</mat-header-cell>
            <mat-cell class="order-cell" *matCellDef="let element" (click)="openDemandDetails(element)"
              [ngClass]="{'red' : element.jiraReference != null}"> {{element.reference}}
            </mat-cell>
            <mat-footer-cell class="hidden-footer" *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- DemandNumber Column -->
          <ng-container matColumnDef="demandNumber" sticky>
            <mat-header-cell class="demand-cell" *matHeaderCellDef mat-header> N° Demande</mat-header-cell>
            <mat-cell class="demand-cell" *matCellDef="let element" (click)="openDemandDetails(element)">
              <b>{{element.demandNumber}}</b>
            </mat-cell>
            <mat-footer-cell class="hidden-footer" *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="demandDescription" sticky>
            <mat-header-cell class="desc-cell" *matHeaderCellDef mat-header> Description</mat-header-cell>
            <mat-cell class="desc-cell" *matCellDef="let element" (click)="openDemandDetails(element)">
              {{element.description}}
            </mat-cell>
            <mat-footer-cell class="desc-cell hidden-footer" *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- ApplicationName Column -->
          <ng-container matColumnDef="applicationName">
            <mat-header-cell class="app-cell" *matHeaderCellDef> Application</mat-header-cell>
            <mat-cell class="app-cell" *matCellDef="let element" (click)="openDemandDetails(element)">
              {{element.applicationName.split('_')[0]}}
            </mat-cell>
            <mat-footer-cell class="app-cell" *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- ServiceType Column -->
          <ng-container matColumnDef="serviceType">
            <mat-header-cell class="service-cell" *matHeaderCellDef> Service</mat-header-cell>
            <mat-cell class="service-cell" *matCellDef="let element" (click)="openDemandDetails(element)">
              {{element.serviceType}}
            </mat-cell>
            <mat-footer-cell class="service-cell" *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- ServiceType Column -->
          <ng-container matColumnDef="prestationType">
            <mat-header-cell class="prestation-cell" *matHeaderCellDef> Type de Prestation</mat-header-cell>
            <mat-cell class="prestation-cell" *matCellDef="let element" (click)="openDemandDetails(element)">
              {{element.prestationType}}
            </mat-cell>
            <mat-footer-cell class="prestation-cell" *matFooterCellDef></mat-footer-cell>
          </ng-container>


          <!-- ServiceType Column -->
          <ng-container matColumnDef="toBeFactured">
            <mat-header-cell class="factured-cell" *matHeaderCellDef> A facturer pour le mois</mat-header-cell>
            <mat-cell class="factured-cell" *matCellDef="let element" (click)="openDemandDetails(element)">
              <div *ngIf="currentDue(element).cost == 0"> --</div>
              <div class="unvalidated" *ngIf="currentDue(element).cost != 0"><b
                  [ngClass]="{'red': hasUnknownCommand(element)}">{{currentDue(element).cost | number:'1.1-2':"fr-FR"}}
                  €</b>
                <mat-icon
                  *ngIf="currentDue(element).status != null && currentDue(element).status != EcheanceStatus.DEFAULT"
                  class="status-icon" [title]="currentDue(element).status"
                  [ngStyle]="{color:Constants.ECHEANCES_STATUSES_ICONS.get(currentDue(element).status!)!.color}">
                  {{Constants.ECHEANCES_STATUSES_ICONS.get(currentDue(element).status!)!.name}}
                </mat-icon>
              </div>
            </mat-cell>
            <mat-footer-cell class="factured-cell" *matFooterCellDef>
              <div>
                <strong *ngIf="totalCost">{{totalCost[currMonthIndex - 1] + totalCost[37]| number:'1.1-2':"fr-FR"}}
                  €</strong>
              </div>
            </mat-footer-cell>
          </ng-container>

          <!-- ServiceType Column -->
          <ng-container matColumnDef="engaged">
            <mat-header-cell class="engaged-cell" *matHeaderCellDef> Engagé</mat-header-cell>
            <mat-cell class="engaged-cell" *matCellDef="let element" (click)="openDemandDetails(element)">
              {{element.cost
              | number:'1.1-2':"fr-FR"}} €
            </mat-cell>
            <mat-footer-cell class="engaged-cell" *matFooterCellDef>
              <div>
                <strong *ngIf="totalCost">{{totalCost[36] | number:'1.1-2':"fr-FR"}} €</strong>
              </div>
            </mat-footer-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell class="status-cell" *matHeaderCellDef> Statut</mat-header-cell>
            <mat-cell class="status-cell" *matCellDef="let element" (click)="openDemandDetails(element)"><span
                class="state" ngClass="{{statusColor(element.state)}}">{{element.state}}</span></mat-cell>

            <mat-footer-cell class="status-cell" *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- Galiléi Label Column -->
          <ng-container matColumnDef="galLabel">
            <mat-header-cell class="gl-cell" *matHeaderCellDef> Projet Galiléi</mat-header-cell>
            <mat-cell class="gl-cell" *matCellDef="let element" (click)="openDemandDetails(element)">
              {{element.galileiProjectCode}}<span *ngIf="element.galileiProjectDescription !== ''"> --
              </span>{{element.galileiProjectDescription}}
            </mat-cell>

            <mat-footer-cell class="gl-cell" *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <!-- Summary Column -->
          <ng-container matColumnDef="summary">
            <mat-header-cell class="summ-cell" *matHeaderCellDef
              [ngClass]="{'hidden' : !_as.getApplicationSettings().display_fusion_pack}"> Total &lt; {{year}}
            </mat-header-cell>
            <mat-cell class="summ-cell" *matCellDef="let element"
              [ngClass]="{'hidden' : !_as.getApplicationSettings().display_fusion_pack}"
              (click)="openDemandDetails(element)">
              {{historicSumm(element)| number:'1.1-2':"fr-FR"}} €
            </mat-cell>

            <mat-footer-cell class="summ-cell"
              [ngClass]="{'hidden' : !_as.getApplicationSettings().display_fusion_pack}" *matFooterCellDef>
              <strong *ngIf="totalCost">{{totalCost[40] | number:'1.1-2':"fr-FR"}} €</strong>
            </mat-footer-cell>
          </ng-container>

          <!-- Echeances Column -->
          <ng-container *ngFor="let i of [].constructor(36); let index = index" matColumnDef="{{'m'+(index+1)}}">
            <mat-header-cell *matHeaderCellDef class="ech ech-{{index%12}}"
              [ngClass]="{'unvalidated' : index == currMonthIndex}"> {{MONTHS[index % 12]}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" (click)="openDemandDetails(element)"
              class="focusable cell-focusable small-font ech ech-{{index%12}} ech-cell">
              <div *ngFor="let echeance of fusedEcheance(element,index)"
                [ngClass]="{'unvalidated' : echeance.projected}">
                <strong
                  [ngClass]="{'faded' : echeance.cost == 0 , 'red': hasUnknownCommand(element) && (!echeance.projected || index == currMonthIndex)}">{{echeance.cost
                  | number:'1.1-2':"fr-FR"}}
                  €</strong>
                <mat-icon
                  *ngIf="echeance.status && echeance.status != EcheanceStatus.DEFAULT && index >= currPack.packMonth-1"
                  class="status-icon" [title]="echeance.status"
                  [ngStyle]="{color:Constants.ECHEANCES_STATUSES_ICONS.get(echeance.status)!.color}">
                  {{Constants.ECHEANCES_STATUSES_ICONS.get(echeance.status)!.name}}
                </mat-icon>
                <br>
                <span
                  [ngClass]="{'red': hasUnknownCommand(element) && (!echeance.projected || index == currMonthIndex)}">Charge:
                  {{echeance.load | number:'1.1-2':"fr-FR"}}</span>
              </div>
              <div *ngIf="getEcheancesOfMonth(element,index).length == 0"> --</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="small-font ech ech-{{index%12}} ech-cell">
              <div>
                <strong *ngIf="totalCost">{{totalCost[index] | number:'1.1-2':"fr-FR"}} €</strong>
              </div>
            </mat-footer-cell>
          </ng-container>

          <!-- Header Group 3 : Year 2 -->
          <ng-container matColumnDef="header-row-second-group">
            <mat-header-cell *matHeaderCellDef class="gr2 gr"
              [ngStyle]="{width:_as.getApplicationSettings().display_fusion_pack ? '2952px': '2742px'}"
              [attr.colspan]="5">
            </mat-header-cell>
          </ng-container>

          <!-- Header Group 2 : Year 1 -->
          <ng-container matColumnDef="header-row-third-group">
            <mat-header-cell *matHeaderCellDef class="gr3 gr" [attr.colspan]="12">
              Echéances de {{getYear()}}
            </mat-header-cell>
          </ng-container>

          <!-- Header Group 3 : Year 2 -->
          <ng-container matColumnDef="header-row-fourth-group">
            <mat-header-cell *matHeaderCellDef class="gr4 gr" [attr.colspan]="12">
              Echéances de {{getYear() + 1}}
            </mat-header-cell>
          </ng-container>

          <!-- Header Group 4 : Year 3 -->
          <ng-container matColumnDef="header-row-fifth-group">
            <mat-header-cell *matHeaderCellDef class="gr4 gr" [attr.colspan]="12">
              Echéances de {{getYear() + 2}}
            </mat-header-cell>
          </ng-container>


          <mat-header-row [class.inactive]="isTableLoading"
            *matHeaderRowDef="['header-row-second-group', 'header-row-third-group','header-row-fourth-group','header-row-fifth-group']; sticky: true;">
          </mat-header-row>
          <mat-header-row [class.inactive]="isTableLoading"
            *matHeaderRowDef="DISPLAYED_COLUMNS ; sticky: true;"></mat-header-row>

          <mat-row [class.inactive]="isTableLoading" class="focusable" [ngClass]="isOvershoot(row) ? 'overshoot' : ''"
            *matRowDef="let row; columns: DISPLAYED_COLUMNS;">
          </mat-row>
          <mat-footer-row [class.inactive]="isTableLoading"
            *matFooterRowDef="DISPLAYED_COLUMNS ; sticky: true ;"></mat-footer-row>
            <ng-template matNoDataRow>
              <div class="no-data-row" *ngIf="!isTableLoading">
                Il n'y a aucune demande à afficher.
              </div>
            </ng-template>
        </mat-table>

      </div>
      <div class="bottomContainer">
        <div class="stats">
          <div class="const_FinM-1">
            <b>Constaté fin M-1:</b> {{getEndMinusOne()| number:'1.1-2':"fr-FR"}} €
          </div>
          <div class="const_Fin-M">
            <b>Constaté fin M:</b> {{getEnd()| number:'1.1-2':"fr-FR"}} €
          </div>
          <div class="Const_Eng">
            <b>Constaté/Engagé:</b> {{100 * getEnd() / totalCost[36]| number:'1.1-2':"fr-FR"}} %
          </div>
        </div>
        <div class="filterContainer" *ngIf="!isAppNamesNavVisible">
          <div class="orderFilter">
            <mat-form-field>
              <mat-label>Filtre Commande</mat-label>
              <input id="filterReference" matInput #input (keyup)="reffilterList($event)" [(ngModel)]="reffilterValue">
            </mat-form-field>
          </div>
          <div class="demandFilter">
            <mat-form-field>
              <mat-label>Filtre Demande</mat-label>
              <input matInput #input (keyup)="filterList($event)" [(ngModel)]="filterValue">
            </mat-form-field>
          </div>
        </div>
        <div class="exportYearAndComment">
          <div class="yearSelect">
            <mat-form-field class="year-input" appearance="outline">
              <mat-label>Année de l'export</mat-label>
              <input matInput [(ngModel)]="exportYear" class="year-input" type="number" placeholder="2024"
                [disabled]="exportSpinner">
            </mat-form-field>
          </div>
          <div class="packExport">
            <button mat-raised-button class="blue" [style.background-color]="theme.getColor('Main Dark')"
              (click)="downloadFile()" [disabled]="exportSpinner">
              Exporter le pack
            </button>
          </div>
          <div class="CommentsExport">
            <button mat-raised-button class="indigo " [style.background-color]="theme.getColor('Main Dark')"
              (click)="exportData()" [disabled]="exportSpinner"
              *ngIf="authenticationService.isUserAdminOrServiceManagerOrManagerCDS()">
              Exporter les commentaires
            </button>
          </div>
          <div class="AnneeCDAFilter" >
            <mat-form-field appearance="outline" color="accent" [ngClass]="{'hidden' : !_as.getApplicationSettings().display_fusion_pack || isFilterCDADesactivated}" title="Filtre Année CDA - Pack M+1">
              <mat-label>Filtre Année CDA - Pack M+1</mat-label>
              <mat-select [formControl]="displayYearCDAFilter" (closed)="getFilteredAndPagedDemandsAndTotals()">
                <mat-option [value]=0>Sup. filtre</mat-option>
                <mat-option *ngFor="let year of displayYearCDAFilterOptions" [value]=(year)>
                  {{ year }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="PaginatorContainer">
          <mat-paginator [length]="resultsLength" [pageSize]="paginatorNumberFromProfile()"
            [pageSizeOptions]="[5, 10, 25, 100]" (page)="getFilteredAndPagedDemandsAndTotals()"></mat-paginator>
        </div>
      </div>

    </div>
  </div>
</div>